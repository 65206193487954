import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';

const TimerDashboard = () => {
  const [totalTimeMinutes, setTotalTimeMinutes] = useState(60);
  const [totalQuestions, setTotalQuestions] = useState(10);
  const [timeLeft, setTimeLeft] = useState(totalTimeMinutes * 60);
  const [questionsLeft, setQuestionsLeft] = useState(totalQuestions);
  const [currentQuestionTime, setCurrentQuestionTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isSetup, setIsSetup] = useState(true);

  const timePerQuestion = Math.floor((totalTimeMinutes * 60) / totalQuestions);
  const averageTimeLeft = questionsLeft > 0 ? Math.floor(timeLeft / questionsLeft) : 0;
  const isAverageTimeBelowThreshold = averageTimeLeft < timePerQuestion;

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (isRunning) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        setCurrentQuestionTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(Math.abs(seconds) / 60);
    const secs = Math.abs(seconds) % 60;
    return `${seconds < 0 ? '-' : ''}${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleMarkQuestion = () => {
    if (questionsLeft > 0) {
      setQuestionsLeft((prev) => prev - 1);
      setCurrentQuestionTime(0);
    }
  };

  const toggleTimer = () => {
    setIsRunning((prev) => !prev);
  };

  const handleSetup = () => {
    setTimeLeft(totalTimeMinutes * 60);
    setQuestionsLeft(totalQuestions);
    setCurrentQuestionTime(0);
    setIsRunning(false);
    setIsSetup(false);
  };

  const currentQuestionTimeLeft = timePerQuestion - currentQuestionTime;
  const isOverspending = currentQuestionTime >= timePerQuestion;
  const questionTimeColor = isOverspending ? 'text-red-500' : 'text-green-500';

  const isTestCompleted = timeLeft === 0 || questionsLeft === 0;

  if (isSetup) {
    return (
      <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md space-y-4">
        <h1 className="text-2xl font-bold text-center">Timer Setup</h1>
        <div className="space-y-2">
          <label className="block">
            Total Time (minutes):
            <Input 
              type="number" 
              value={totalTimeMinutes} 
              onChange={(e) => setTotalTimeMinutes(Number(e.target.value))}
              min="1"
            />
          </label>
          <label className="block">
            Total Questions:
            <Input 
              type="number" 
              value={totalQuestions} 
              onChange={(e) => setTotalQuestions(Number(e.target.value))}
              min="1"
            />
          </label>
        </div>
        <Button onClick={handleSetup} className="w-full">Start Timer</Button>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md space-y-4">
      <h1 className="text-2xl font-bold text-center">Timer Dashboard</h1>
      
      <div className="space-y-2">
        <p className="text-lg">Total Time Left: <span className="font-bold">{formatTime(timeLeft)}</span></p>
        <p className="text-lg">Questions: <span className="font-bold">{questionsLeft}/{totalQuestions}</span></p>
        <p className="text-lg">
          Time Left for Current Question: 
          <span className={`font-bold ${questionTimeColor}`}> {formatTime(currentQuestionTimeLeft)}</span>
        </p>
        <p className="text-lg">
          Avg. Time Left per Question: 
          <span className={`font-bold ${isAverageTimeBelowThreshold ? 'text-yellow-500' : 'text-green-500'}`}>
            {' '}{formatTime(averageTimeLeft)}
          </span>
        </p>
        <p className="text-sm">Time per question: {formatTime(timePerQuestion)}</p>
      </div>

      {!isTestCompleted && (
        <div className="flex justify-between">
          <Button onClick={handleMarkQuestion} disabled={questionsLeft === 0}>
            Mark Question
          </Button>
          <Button onClick={toggleTimer}>
            {isRunning ? 'Pause' : 'Start'}
          </Button>
        </div>
      )}

      {timeLeft === 0 && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Time's up!</AlertTitle>
          <AlertDescription>
            The total time for the test has elapsed.
          </AlertDescription>
        </Alert>
      )}

      {questionsLeft === 0 && (
        <Alert>
          <CheckCircle className="h-4 w-4" />
          <AlertTitle>All questions answered!</AlertTitle>
          <AlertDescription>
            You've completed all the questions.
          </AlertDescription>
        </Alert>
      )}

      {isTestCompleted ? (
        <Button onClick={() => setIsSetup(true)} className="w-full">Restart Timer</Button>
      ) : (
        <Button onClick={() => setIsSetup(true)} className="w-full">Reset</Button>
      )}
    </div>
  );
};

export default TimerDashboard;
